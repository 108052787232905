<template>
  <section class="SectionControls">
    <div class="SectionControls__controls">
      <div class="SectionControls__controls-item">
        <Button
          v-if="buttonLeftText"
          :disabled="buttonLeftDisabled"
          :type="buttonLeftType"
          @click="$emit('button-left-click')"
        >
          {{ buttonLeftText }}
        </Button>
      </div>
      <div class="SectionControls__policy-text"  v-if="policyText">
        <p class="policy-text" v-html="policyText"></p>
      </div>
      <div class="StatusSection__controls-item">
        <Button
          v-if="buttonRightText"
          :disabled="buttonRightDisabled"
          :type="buttonRightType"
          @click="$emit('button-right-click')"
        >
          {{ buttonRightText }}
        </Button>
      </div>
    </div>
  </section>
</template>

<script>
import Button from "@/components/Button/Button.component";

export default {
  name: 'SectionControls',
  components: {Button},
  props: {
    buttonLeftText: {type: String, required: false},
    buttonLeftDisabled: {type: Boolean, default: false},
    buttonLeftType: {type: String, default: 'primary', required: false},
    buttonRightText: {type: String, required: false},
    buttonRightDisabled: {type: Boolean, default: false},
    buttonRightType: {type: String, default: 'primary', required: false},
    policyText: {type: String, default: ''},
  }
}
</script>

<style lang="scss">
.SectionControls {
  &__controls {
    display: flex;
    justify-content: space-between;
  }

  &__policy-text {
    padding: 10px;
    width: 100%;
    font-size: 12px;
  }

  button {
    min-width: 120px;
  }
}
</style>
