<template>
  <section class="request-accreditation">
    <SpaceDescription>
      <Headline :size="1">Request accreditation</Headline>
    </SpaceDescription>

    <Tabs
      :tabs="tabs"
      :activeTab="activeTab"
    >
      <component :is="activeTab" @tabchange="changeTab" />
    </Tabs>

  </section>
</template>

<script>
import EventInformation from "./RequestAccreditationEventInfo.view";
import ApplicantInformation from "./RequestAccreditationApplicantInfo.view";
import Summary from "./RequestAccreditationSummary.view";
import RouterNames from '@/router/route.names';

import SpaceDescription from "@/components/SpaceDescription/SpaceDescription.component";
import Headline from "@/components/Headline/Headline.component";
import Paragraph from "@/components/Paragraph/Paragraph.component";
import Tabs from "@/components/Tabs/Tabs.component";
import Tab from "@/components/Tabs/Tab.component";
import get from "lodash.get";

export default {
  name: 'RequestAccreditation',
  components: {
    SpaceDescription,
    Headline,
    Paragraph,
    ApplicantInformation,
    EventInformation,
    Summary,
    Tabs,
    Tab
  },
  data: () => ({
    tabs: [
      {title: 'Event Information', tab: 'EventInformation'},
      {title: 'Accreditation Information', tab: 'ApplicantInformation'},
      {title: 'Summary', tab: 'Summary'}
    ],
  }),
  computed: {
    permissions() {return this.$store.getters['user/permissions']},
    activeTab() { return get(this, '$store.state.accreditationRequest.activeTab') },
    globalSelectedSeason() {
      return get(this, '$store.state.event.selectedSeason')
    }
  },
  watch: {
    permissions() {this.checkPermissions()},
    globalSelectedSeason() {
      this.getAccreditationsMatrix(this.globalSelectedSeason);
    }
  },
  methods: {
    changeTab(tab) { this.$store.commit('accreditationRequest/changeActiveTab', tab) },
    checkPermissions() {
      const permitted = this.$store.getters['user/haveAccessToAccreditation'];
      if (!permitted) this.$router.push({name: RouterNames.Home})
    },
    getAccreditationsMatrix(selectedSeason) {
      this.$store.dispatch('accreditationRequest/getAccreditationsMatrix', {selectedSeason})
    },
  },
  created() {
    this.checkPermissions();

    this.getAccreditationsMatrix(this.globalSelectedSeason);
  }
}
</script>

<style lang="scss">
.request-accreditation {}
</style>
