<template>
  <section class="select-applicants-table">
    <Headline :size="4">Step 2: Select persons</Headline>

    <div class="select-applicants-table__filters">
      <div class="input-wrapper">
        <Input
            placeholder="Search by name"
            v-model="nameSearchKey"
            @input="onSearch"
        />
        <Button
            class="button-component rectangle primary"
            :style="{marginRight: '16px'}"
            @click="onSearch">
          Search
        </Button>
        <Button
            class="ml-0 px-0 text-nowrap"
            type="link"
            @click="clearFilters">
          Clear filters
        </Button>
      </div>
      <div class="table-select-button">
        <Button
            type="secondary"
            @click="clearAllUsers">
          Clear all
        </Button>
        <Button
            type="secondary"
            @click="selectAllUsers">
          Select all
          <span>+</span>
        </Button>
      </div>
    </div>

    <div class="separator"/>

    <div class="Table empty" v-if="!applicantsTableCollection || !applicantsTableCollection.models.length">Nothing found</div>
    <Table
        v-else
        :collection="applicantsTableCollection"
        :columns="$static.table.columns"
        :height="320"
        :style="{minWidth: '800px'}"
    >
      <template v-slot:table-cell-organization="slot">
        {{ getOrganization(slot.model) || '--' }}
      </template>
      <template v-slot:table-cell-firstName="slot">
        {{ slot.data }}
      </template>
      <template v-slot:table-cell-lastName="slot">
        {{ slot.data }}
      </template>
      <template v-slot:table-cell-birthDate="slot">
        {{ moment(slot.data).format('DD.MM.YYYY') }}
      </template>
      <template v-slot:table-cell-gender="slot">
        <span class="table-cell-capitalize">{{ slot.data || '--' }}</span>
      </template>
      <template v-slot:table-cell-buttons="slot">
        <Button
            icon="ADD"
            form="round"
            type="primary"
            :disabled="!!slot.model.invalidFields.length"
            :color="(slot.model.invalidFields.length)?'#f3371f': (isUserSelected(slot.model)?'gray' : '')"
            @click="selectAccreditationUser(slot.model)"
        />
        <Button
            v-if="slot.model.invalidFields && slot.model.invalidFields.length"
            icon="EDIT"
            form="round"
            type="danger"
            @click="redirectToEdit(slot.model)"
        />
      </template>
    </Table>
    <TableControls @setLimit="updateLimit($event)" @selectPage="updatePage($event)"
                   :count="request.meta ? request.meta.count : 0"
                   :offset="request.meta ? request.meta.offset : 0"
                   :limit="limit"
                   :style="{minWidth: '800px'}" />
  </section>
</template>

<script>
import Table from '@/components/Table/Table.component';
import TableControls from "@/components/Table/TableControls.component";
import Headline from '@/components/Headline/Headline.component';
import Input from '@/components/Inputs/Input.component';
import Button from '@/components/Button/Button.component';

import {ApplicantsCollection} from "@/classes/collections/Applicants.collection";
import {TableColumnsCollection} from "@/components/Table/classes/TableColumns.collection";
import {TableColumnModel} from "@/components/Table/classes/TableColumn.model";

import set from "lodash.set";
import lowerCase from "lodash.lowercase";
import moment from "moment";
import get from "lodash.get";
import RouterNames from "@/router/route.names";
import {DtoApiPersonsRequest} from "@/classes/dto/api/person/dto.api.persons.request";
import {DtoApiEventPerson} from '@/classes/dto/api/accreditations/dto.api.get-event-person.request';

export default {
  name: 'SelectApplicationTable',
  components: {
    TableControls,
    Table,
    Headline,
    Input,
    Button
  },
  data: () => ({
    request: new DtoApiPersonsRequest(),
    applicants: null,
    nameSearchKey: '',
    applicantsTableCollection: new ApplicantsCollection(),
    limit: 25,
    page: 1,
    pages: 1,
    requiredFields: [],
  }),
  computed: {
    season() {
      return get(this, '$store.state.event.selectedSeason', null)
    },
    isTournamentMode() {
      return Boolean(this.season?.isTournamentMode);
    },
  },
  methods: {
    moment,
    async updatePage(page) {
      this.page = page;
      await this.getCollection();
    },
    async updateLimit(limit) {
      this.page = 1;
      this.limit = limit;
      await this.getCollection();
    },
    async getCollection(searchName = '') {
      const offset = (searchName) ? 0 : (this.page - 1) * this.limit;
      const {limit} = this;
      this.request = await this.request.$getPersons({
        organizationId: this.$store.state.user.account.id,
        limit,
        offset,
        searchName,
      });
      this.pages = Math.ceil(this.request.meta.count / this.limit);
      this.applicantsTableCollection = this.request.$toAccreditationApplicantsCollection();
      this.applicants = this.applicantsTableCollection;

      this.applicantsTableCollection.models.map(applicant => {
        this.requiredFields.map(field => {
          if (!applicant[field] || applicant[field].length === 0) {
            console.debug('Applicant has no ' + field, applicant);
            applicant.invalidFields.push(field);
          }
        });
      });
    },
    getOrganization(model) {
      return get(model, 'organization.name');
    },
    getNationality(applicant) {
      return get(applicant, 'data.country');
    },
    async getRequiredFields() {
      let eventType;
      let eventId;
      if (this.isTournamentMode) {
        eventType = 'competition';
        eventId = this.season?.competitionId;
      } else {
        eventType = 'match';
        eventId = this.$store.state.accreditationRequest.eventInfo.match?.matchId;
      }
      if (!eventType || !eventId) {
        return [];
      }
      const organizationId = this.$store.getters['user/organizationId'];
      const eventPersonFields = await new DtoApiEventPerson().$get(eventType, eventId, organizationId);
      const requiredFields = new Set();
      if (eventPersonFields.data && eventPersonFields.data.length) {
        eventPersonFields.data.forEach(item => {
          item.person_fields.requiredFields.forEach(field => requiredFields.add(field));
        });
      }
      return Array.from(requiredFields);
    },
    selectAccreditationUser(user) {
      this.$store.commit('accreditationRequest/selectAccreditationUser', user)
    },
    selectAllAccreditationUsers(user) {
      this.$store.commit('accreditationRequest/selectAllAccreditationUsers', user)
    },
    isUserSelected(user){
      return  get(this, '$store.state.accreditationRequest.selectedUsers').find(({id}) => id === user.id);
    },

    onSearch() {
      this.getCollection(this.nameSearchKey);
    },
    selectAllUsers() {
      this.selectAllAccreditationUsers(this.applicants.models)
    },
    clearAllUsers() {
      this.selectAllAccreditationUsers([])
    },
    clearFilters() {
      this.nameSearchKey = '';
      this.getCollection();
    },
    redirectToEdit(person) {
      const personId = person.id;
      const invalidFields = person.invalidFields;
      const name = RouterNames.IndexRoutes.DashboardRoutes.PersonRoutes.PersonEdit;
      const params = {personId, invalidFields};
      params.fromAccreditationCreatePage = true
      this.$router.push({name, params});
    }
  },
  async created() {
    this.requiredFields = await this.getRequiredFields();
    this.getCollection();
    set(this, '$static.table.columns', new TableColumnsCollection([
      new TableColumnModel('organization', 'ORGANISATION', '50px'),
      new TableColumnModel('firstName', 'FIRSTNAME', '50px'),
      new TableColumnModel('lastName', 'LASTNAME', '50px'),

      new TableColumnModel('birthDate', 'DATE OF BIRTH', '50px'),
      new TableColumnModel('gender', 'GENDER', '140px'),
      new TableColumnModel('buttons', '', '50px'),
    ]));
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/mixins/ellipsis.mixin";

.select-applicants-table {
  &__filters {
    display: flex;
    > div {
      flex-grow: 1;
    }
    .table-select-button {
      display: flex;
      justify-content: flex-end;
    }

    .select-all-checkbox {
      display: flex;
      align-items: center;
      margin-right: 30px;
    }

    .input-wrapper {
     display: flex;
      > section {
        width: 70%;
      }
    }

    ::v-deep .button-component {
      margin-left: 20px;
    }
  }

  .separator {
    border-bottom: 1px solid #D8DAE3;
    margin: 32px 0 23px;
  }

  ::v-deep .InputLabel__label {
    margin-right: 40px;
  }

  ::v-deep .Input__input {
    font-size: 12px;
    font-weight: bold;
    outline: none;
    border-radius: 12px;
  }

  .table-cell-capitalize {
    text-transform: capitalize !important;
  }

  ::v-deep .Table__table-cell_buttons .Table__table-cell-container {
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  ::v-deep .button-component__icon {
    font-size: 37px !important;
  }

  ::v-deep .multiselect {
    width: 194px;
    font-weight: bold;
  }
}
</style>

<style lang="scss">
  .select-applicants-table .InputLayout .InputLayout__labels .InputLabel .InputLabel__label {
    margin-right: 0;
  }
  .select-applicants-table__filters .form-group div:first-child {
    padding-right: 0;
  }
</style>
