<template>
  <section class="assign-accreditation-table">
    <Headline :size="4">Step 3: Assign Accreditation</Headline>

    <div class="assign-accreditation-table__filters">
      <Button
          class="assign-accreditation-table__assign-all-btn"
          @click="assignAllAccreditation"
      >
        Assign all at once
      </Button>
      <InputDropdown
          class="input-width"
          :allow-empty="false"
          trackLabel="name"
          placeholder="Select category"
          :options="getMatrixCategories() || []"
          v-model="filters.category"
          @input="filters.subCategory = null; filters.accreditationType = null"
      />

      <InputDropdown
          class="input-width"
          :allow-empty="false"
          placeholder="Select Designation"
          trackLabel="name"
          :options="filters.category ? filters.category.subcategoryTypes : []"
          v-model="filters.subCategory"
          :disabled="!filters.category"
          @input="filters.accreditationType = null"
      />
      <InputDropdown
          class="input-width"
          :allow-empty="false"
          trackLabel="name"
          placeholder="Select SAD type"
          :options="filters.subCategory ? filters.subCategory.accreditationTypes : []"
          v-model="filters.accreditationType"
          :disabled="!filters.subCategory"
      />

      <div class="dummy"/>
    </div>

    <div class="separator"/>

    <div v-if="!accreditationSelectedUsers.length">Nothing found</div>
    <Table
        v-else
        :collection="accreditationTableCollection"
        :columns="$static.table.columns"
        :style="{minWidth: '800px'}"
    >
      <template v-slot:table-cell-firstName="slot">
        {{ slot.data }}
      </template>
      <template v-slot:table-cell-lastName="slot">
        {{ slot.data }}
      </template>
      <template v-slot:table-cell-category="slot">
        <InputDropdown
            class="input-width"
            :allow-empty="false"
            trackLabel="name"
            placeholder="Select category"
            :options="getMatrixCategories() || []"
            @input="handlePersonCategoryChange(slot.model)"
            v-model="slot.model.category"
        />
      </template>
      <template v-slot:table-cell-subCategory="slot">
        <InputDropdown
            class="input-width"
            :allow-empty="false"
            trackLabel="name"
            :options="slot.model.category.subcategoryTypes || []"
            placeholder="Select Designation"
            @input="handlePersonDesignationChange(slot.model)"
            v-model="slot.model.subCategory"
            :disabled="!slot.model.category"
        />
      </template>
      <template v-slot:table-cell-accreditationType="slot">
        <InputDropdown
            class="input-width"
            :allow-empty="false"
            trackLabel="name"
            :options="getPersonAccreditationTypes(slot.model.subCategory) || []"
            placeholder="Select SAD type"
            v-model="slot.model.accreditationType"
            :disabled="!slot.model.subCategory"
        />
      </template>
      <template v-slot:table-cell-venues="slot">
        <InputDropdownMultiselect
          class="input-width"
          trackBy="venueId"
          :trackLabel="venueLabel"
          :options="seasonVenuesList"
          :multiple="true"
          :group-select="enableAllVenuesFeature"
          :group-values="enableAllVenuesFeature ? 'items' : null"
          :group-label="enableAllVenuesFeature ? 'label' : null"
          placeholder="Select Venues"
          keepOpen
          v-model="slot.model.venues"
        >
          <template v-slot:selection="{ search, remove, values }">
            <template v-if="enableAllVenuesFeature && values.length && values.length >= seasonVenues.length">
              <span class="multiselect__tag multiselect__all">
                <span v-text="allVenuesLabel"/>
              </span>
            </template>
            <template v-else>
              <div class="multiselect__tags-wrap" v-show="values.length > 0">
                <template v-for="(option, index) of values.slice(0, multiselectLimit)" @mousedown.prevent>
                  <span class="multiselect__tag" :key="index">
                    <span v-text="getMultiselectOptionLabel(option)"/>
                    <i
                      tabindex="1"
                      @keypress.enter.prevent="remove(option)"
                      @mousedown.prevent="remove(option)"
                      class="multiselect__tag-icon"
                    />
                  </span>
                </template>
              </div>
              <template v-if="values && values.length > multiselectLimit">
                <strong class="multiselect__strong" v-text="limitMultiselectText(values.length - multiselectLimit)"/>
              </template>
            </template>
          </template>
        </InputDropdownMultiselect>
      </template>
      <template v-slot:table-cell-activation="slot">
        <InputDropdown
            class="input-width"
            :allow-empty="false"
            trackLabel="activity.name"
            :options="activationsArray || []"
            placeholder="Select Activation"
            v-model="slot.model.activation"
        />
      </template>
      <template v-slot:table-cell-buttons="slot">
        <Button
            @click="selectAccreditationUser(slot.model)"
            icon="REMOVE_OUTLINE"
            form="link"
            type="secondary"
            class="p-0"
        />
      </template>
    </Table>
  </section>
</template>

<script>
import Table from '@/components/Table/Table.component';
import Headline from '@/components/Headline/Headline.component';
import InputDropdown from '@/components/Inputs/InputDropdown.component';
import InputDropdownMultiselect from "@/components/Inputs/InputDropdownMultiselect.component.vue";
import Button from '@/components/Button/Button.component';

import {ApplicantsCollection} from "@/classes/collections/Applicants.collection";
import {TableColumnsCollection} from "@/components/Table/classes/TableColumns.collection";
import {TableColumnModel} from "@/components/Table/classes/TableColumn.model";

import get from "lodash.get";
import set from "lodash.set";

export default {
  name: 'AssignAccreditationTable',
  components: {
    Table,
    Headline,
    InputDropdown,
    InputDropdownMultiselect,
    Button
  },
  data: () => ({
    accreditationTableCollection: new ApplicantsCollection([]),
    filters: {
      category: '',
      subCategory: '',
      accreditationType: ''
    },
    venueLabel: 'localTitle',
    enableAllVenuesFeature: false,
    allVenuesLabel: 'All venues',
    multiselectLimit: 1,
  }),
  computed: {
    accreditationSelectedUsers() {
      return get(this, '$store.state.accreditationRequest.selectedUsers')
    },
    matrixCategories() {
      return get(this, '$store.state.accreditationRequest.accreditationMatrix.categories')
    },
    matrixAccreditationTypes() {
      return get(this, '$store.state.accreditationRequest.accreditationMatrix.accreditationTypes')
    },
    matrixSubcategories() {
      return get(this, '$store.state.accreditationRequest.accreditationMatrix.subcategories')
    },
    activationsArray() {
      return get(this, '$store.state.sponsorActivationsList.activationsArray')
    },
    organizationId() {
      return get(this, '$store.state.user.account.id')
    },
    season() {
      return get(this, '$store.state.event.selectedSeason', null)
    },
    isTournamentMode() {
      return Boolean(this.season?.isTournamentMode);
    },
    seasonVenues() {
      return this.season?.venues || [];
    },
    seasonVenuesList() {
      if (!this.enableAllVenuesFeature) {
        const list = [...this.seasonVenues];
        //push venue with "all venues" label to the top of the list if existing
        const targetLabel = this.allVenuesLabel?.toLowerCase();
        const targetIndex = list.map(venue => venue.localTitle?.toLowerCase()).indexOf(targetLabel);
        if (targetIndex >= 0) {
          return [list[targetIndex], ...list.slice(0, targetIndex), ...list.slice(targetIndex + 1)];
        }
        return list;
      }

      return [
        {
          label: this.allVenuesLabel,
          items: this.seasonVenues,
        },
      ];
    },
  },
  watch: {
    accreditationSelectedUsers: {
      handler: function (newVal) {
        if (newVal && newVal.length) {
          this.accreditationTableCollection = new ApplicantsCollection(newVal)
        }
      },
      immediate: true
    }
  },
  methods: {
    selectAccreditationUser(user) {
      this.$store.commit('accreditationRequest/selectAccreditationUser', user)
    },
    assignAllAccreditation() {
      this.accreditationSelectedUsers.forEach(item => {
        item.category = this.filters.category
        item.subCategory = this.filters.subCategory
        item.accreditationType = this.filters.accreditationType
      });
    },
    getActivations(data) {
      this.$store.dispatch('sponsorActivationsList/getActivations', data)
    },
    handlePersonCategoryChange(person) {
      this.accreditationSelectedUsers.forEach(item => {
        if (item.id === person.id) {
          item.accreditationType = ''
          item.subCategory = ''
        }
      });
    },
    handlePersonDesignationChange(person) {
      this.accreditationSelectedUsers.forEach(item => {
        if (item.id === person.id) {
          item.accreditationType = ''
        }
      });
    },
    getMatrixCategories() {
      return this.matrixCategories.filter(item => {
        let hasCurrentOrganization = false

        item.organizations.forEach((item2) => {
          if (item2.organizationId === this.organizationId) {
            hasCurrentOrganization = true
          }
        })

        return hasCurrentOrganization
      })
    },
    getPersonAccreditationTypes(subCategory)
    {
      let accreditationTypes = []

      this.matrixSubcategories.forEach((item) => {
        if (item.subcategoryId === subCategory.subcategoryId) {
          accreditationTypes = item.accreditationTypes
        }
      })

      return accreditationTypes;
    },
    getMultiselectOptionLabel (option) {
      const isEmpty = (opt) => {
        if (opt === 0) return false
        if (Array.isArray(opt) && opt.length === 0) return true
        return !opt
      };

      if (isEmpty(option)) return ''
      /* istanbul ignore else */
      if (option.isTag) return option.label
      /* istanbul ignore else */
      if (option.$isLabel) return option.$groupLabel

      let label = option ? option[this.venueLabel] : ''
      /* istanbul ignore else */
      if (isEmpty(label)) return ''
      return label
    },
    limitMultiselectText (count) {
      return `and ${count} more`;
    },
  },
  created() {
    const eventInfo = this.$store.state.accreditationRequest.eventInfo;
    this.getActivations({matchId: eventInfo.match ? eventInfo.match.matchId : null, limit: 100})

    let columns = [
      new TableColumnModel('firstName', 'FIRST NAME', '200px'),
      new TableColumnModel('lastName', 'LAST NAME', '200px'),
      new TableColumnModel('category', 'CATEGORY', '250px'),
      new TableColumnModel('subCategory', 'DESIGNATION', '250px'),
      new TableColumnModel('accreditationType', 'SAD TYPE', '250px'),
    ];

    if (this.isTournamentMode) {
      columns.push(new TableColumnModel('venues', 'VENUES', '400px'));
    }

    if (this.$store.state.user.account && this.$store.state.user.account.categories.length > 0) {
      if (this.$store.getters['user/hasPARCategory']) {
        columns.push(new TableColumnModel('activation', 'LINKED PARTNER ACTIVATION', '250px'))
      }
    }

    columns.push(new TableColumnModel('buttons', '', '60px'))

    set(this, '$static.table.columns', new TableColumnsCollection(columns));
  },
}
</script>

<style lang="scss">
.assign-accreditation-table__filters .col-md-8 {
  width: 100% !important;
}

.applicant-information .Table__table-cell-container .col-md-12 {
  padding-left: 0 !important;
}

.assign-accreditation-table {
  .InputDropdown {
    .col-md-8 {
      width: 100% !important;
      padding-left: 0;
    }

    .multiselect {
      margin: 0 !important;
    }
  }
}
</style>


<style lang="scss" scoped>
@import "src/styles/main.scss";

.assign-accreditation-table {

  .InputDropdown {
    width: 300px;
  }

  .Table .InputDropdown {
    width: 100%;
    padding-right: 5px;
  }

  &__filters {
    display: flex;
    justify-content: space-between;

    .select-all-checkbox {
      display: flex;
      align-items: center;
      margin-right: 30px;
    }

    .input-wrapper {
      flex: 1;
    }
  }

  .separator {
    border-bottom: 1px solid #D8DAE3;
    margin: 32px 0 23px;
  }

  .dummy {
    width: 45px;
  }

  &__assign-all-btn {
    width: 120px;
    padding: 0;
  }


  ::v-deep .Table__table-row {
    padding-left: 0;
    padding-right: 0;
  }

  ::v-deep .Table__table-header-content {
    font-size: 12px;
  }

  ::v-deep .Table__table-cell_buttons .Table__table-cell-container {
    justify-content: flex-end;
  }


  ::v-deep .button-component__icon {
    color: $secondary-color !important;
    font-size: 36px !important;
  }

  .multiselect__all {
    padding-right: 10px;
  }
}
</style>
