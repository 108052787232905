<template>
  <div class="summary">
    <Section>
      <div class="summary__section-success-request" v-if="isAccreditationCreatedSuccessful">
        <Headline
            :class="[
            'summary__section-headline',
            'summary__section-headline_centered'
          ]"
            :size="3"
        >
          Your request was submitted successfully!
        </Headline>
        <div class="summary__section-check-container">
          <img
              class="summary__section-check"
              :src="$static.icons.check"
              alt=""
          />
        </div>
      </div>
      <div v-else>
        <div class="summary__wrapper">
          <Headline
              :size="3"
          >
            Summary
          </Headline>
          <p class="mb-3">Please review the accreditation summary before submitting the request(s).</p>
        </div>

        <div
            v-if="eventType === 'match' && (matchDay && match) && !isTournamentMode"
            class="summary__match-info"
        >
          <div class="summary__match-info-wrapper">
            <div class="summary__match-info-block">
              <div class="summary__match-info-title">MATCHDAY</div>
              {{ matchDay.name }}
            </div>

            <div class="summary__match-info-block">
              <div class="summary__match-info-title">Match</div>
              {{ match.name }}
            </div>

            <div class="summary__match-info-block">
              <div class="summary__match-info-title">Venue</div>
              {{ match.venue ? match.venue.localTitle : '' }}
            </div>
          </div>

          <div class="separator"/>
        </div>

        <div v-if="!summaryTableCollection && !accreditationSelectedUsers.length">Nothing found</div>
        <Table v-else :collection="summaryTableCollection" :columns="$static.table.columns">
          <template v-slot:table-cell-name="slot">
            {{ `${slot.data} ` }}
          </template>
          <template v-slot:table-cell-category="slot">
            {{ slot.data.name }}
          </template>
          <template v-slot:table-cell-subCategory="slot">
            {{ slot.data.name }}
          </template>
          <template v-slot:table-cell-accreditationType="slot">
            {{ slot.data.name }}
          </template>
          <template v-slot:table-cell-venues="slot">
            <template v-if="isAllVenues(slot.data)">
              All venues
            </template>
            <template v-else>
              {{ Array.isArray(slot.data) ? slot.data.map(venue => venue.localTitle).join(venuesGlue) : '' }}
            </template>
          </template>
          <template v-slot:table-cell-activation="slot">
            {{ slot.data ? slot.data.activity.name : '' }}
          </template>
        </Table>
      </div>
    </Section>

    <div v-if="isAccreditationCreatedSuccessful" class="summary__request-btn">
      <SectionControls
          :button-left-text="'Back To Requests'"
          @button-left-click="goToRequests()"
          :button-right-text="'Check Request Details'"
          @button-right-click="goToRequestDetails()"
      />
    </div>
    <div v-else class="btn-wrapper">
      <Button
          type="secondary"
          @click="changeTab('ApplicantInformation')"
      >
        Back
      </Button>
      <Button
          type="primary"
          @click="submit"
          v-if="!showLoader"
      >
        Submit request
      </Button>

      <pulse-loader :loading="showLoader" :color="loaderColor"></pulse-loader>

    </div>
  </div>
</template>

<script>
import set from "lodash.set";
import get from "lodash.get";

import Headline from "@/components/Headline/Headline.component";
import Button from "@/components/Button/Button.component";
import Table from "@/components/Table/Table.component";
import Section from "@/components/Section/Section.component";

import {ApplicantsCollection} from "@/classes/collections/Applicants.collection";
import {TableColumnsCollection} from "@/components/Table/classes/TableColumns.collection";
import {TableColumnModel} from "@/components/Table/classes/TableColumn.model";

import {DtoApiPostAccreditations} from "@/classes/dto/api/accreditations/dto.api.post-accreditations.request";
import icon from "@/assets/DDMCAF/images/check.svg";
import RouterNames from "@/router/route.names";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

import SectionControls from '@/components/Section/SectionControls.component';

export default {
  name: "RequestAccreditationSummary",
  components: {
    Headline,
    Button,
    Table,
    Section,
    PulseLoader,
    SectionControls
  },
  data: () => ({
    DtoApiPostAccreditations: new DtoApiPostAccreditations(),
    summaryTableCollection: new ApplicantsCollection([]),
    request: null,
    showLoader: false,
    loaderColor: "#00A9A0",
    venuesGlue: '; ',
    enableAllVenuesFeature: false,
  }),
  watch: {
    accreditationSelectedUsers: {
      handler: function (newVal) {
        if (newVal && newVal.length) {
          this.summaryTableCollection = new ApplicantsCollection(newVal)
        }
      },
      immediate: true
    }
  },
  computed: {
    isAccreditationCreatedSuccessful() {
      if (!this.request) return false;
      return (this.request.$status === 200);
    },
    accreditationSelectedUsers() {
      return get(this, '$store.state.accreditationRequest.selectedUsers')
    },
    match() {
      return get(this, '$store.state.accreditationRequest.eventInfo.match')
    },
    matchDay() {
      return get(this, '$store.state.accreditationRequest.eventInfo.matchDay')
    },
    eventType() {
      return get(this, '$store.state.accreditationRequest.eventInfo.eventType')
    },
    activation() {
      return get(this, '$store.state.accreditationRequest.eventInfo.activation')
    },
    season() {
      return get(this, '$store.state.event.selectedSeason', null)
    },
    seasonVenues() {
      return this.season?.venues || [];
    },
    isTournamentMode() {
      return Boolean(this.season?.isTournamentMode);
    },
  },
  methods: {
    changeTab(tab) {
      this.$emit('tabchange', tab)
    },
    goToRequests() {
      const name = RouterNames.IndexRoutes.DashboardRoutes.AccreditationRequestsRoutes.AccreditationRequestList;
      this.$router.push({name});
      this.$store.commit('accreditationRequest/clearState')
    },
    goToRequestDetails() {
      let request = this.request.$response.data[0].accreditationId
      this.$router.push({
        name: RouterNames.IndexRoutes.DashboardRoutes.AccreditationRequestsRoutes.AccreditationRequestDetails,
        params: {request}
      })
    },
    isAllVenues(venues) {
      if (!this.enableAllVenuesFeature) {
        return false;
      }
      return venues.length >= this.seasonVenues.length;
    },
    async submit() {
      const store = this.$store.state
      const organizationId = store.user.account.id;
      const matchId = store.accreditationRequest.eventInfo.match ? store.accreditationRequest.eventInfo.match.matchId : null
      const seasonId = store.accreditationRequest.eventInfo.season ? store.accreditationRequest.eventInfo.season.seasonId : null

      const accreditations = this.accreditationSelectedUsers.map(item => ({
        personId: item.id,
        subcategoryId: item.subCategory.subcategoryId,
        bookingId: item.activation.bookingId,
        accreditationTypes: [item.accreditationType.accreditationTypeId],
        allVenues: this.isAllVenues(item.venues),
        venueIds: item.venues?.map(venue => venue.venueId),
      }))

      this.showLoader = true
      this.request = await this.DtoApiPostAccreditations.$post(organizationId, matchId, seasonId, accreditations)
      this.showLoader = false
    }
  },
  created() {
    set(this, '$static.icons.check', icon);

    let columns = [
      new TableColumnModel('firstName', 'FIRST NAME', '150px'),
      new TableColumnModel('lastName', 'LAST NAME', '150px'),
      new TableColumnModel('category', 'CATEGORY', '100px'),
      new TableColumnModel('subCategory', 'DESIGNATION', '100px'),
      new TableColumnModel('accreditationType', 'SAD TYPE', '100px'),
    ]

    if (this.isTournamentMode) {
      columns.push(new TableColumnModel('venues', 'Venues', '400px'));
    }

    if (this.$store.getters['user/hasPARCategory']) {
      columns.push(new TableColumnModel('activation', 'Linked Partner Activation', '100px'))
    }

    set(this, '$static.table.columns', new TableColumnsCollection(columns));
  }
}
</script>

<style lang="scss" scoped>
.summary {
  font-size: 12px;

  &__section {
    &-success-request {
      height: 300px;
      display: flex;
      flex-direction: column;
    }

    &-headline {
      display: flex;

      &_centered {
        justify-content: center;
      }
    }

    &-check {
      width: 130px;
      margin: auto;

      &-container {
        width: 100%;
        display: flex;
        justify-content: center;
        flex: 1;
      }
    }
  }

  p {
    font-size: 11pt;
    color: #212529;
  }

  .separator {
    border-bottom: 2px solid #D8DAE3;
    margin: 32px 0 23px;
  }

  &__request-btn {
    width: 100%;
    text-align: center;
  }

  .btn-wrapper {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__match-info {
    margin-top: 32px;

    &-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &-block {
      width: 50%;
      font-size: 14px;
      font-weight: bold;
    }

    &-title {
      margin-bottom: 24px;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      color: #8f9093;
    }
  }

  .paragraph-component:last-child {
    padding: 0;
  }

  ::v-deep .Table__table-cell {
    color: #000;
    font-size: 12px;
  }

  ::v-deep .Table__table-row {
    border-bottom: none;
  }
}
</style>
