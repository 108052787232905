<template>
  <div class="applicant-information">
    <Section>
      <Headline :size="3">Accreditation Information</Headline>
      <ul style="margin-bottom:40px" v-if="eventInfo.match">
        <li class="accreditation-info-row"><span>Matchday:</span> <span>{{ eventInfo.matchDay.optionLabel }}</span></li>
        <li class="accreditation-info-row"><span>Match:</span> <span>{{ eventInfo.match.name }}</span></li>
        <li class="accreditation-info-row"><span>Venue:</span> <span>{{ eventInfo.match.venue.localTitle }}</span></li>
      </ul>
      <ul style="margin-bottom:40px" v-if="eventInfo.season">
        <li class="accreditation-info-row"><span>Season:</span> <span>{{ eventInfo.season.title }}</span></li>
        <li class="text-danger font-weight-bold pt-2" v-if="!hasAccreditationMatches">
          Requesting accreditations for this Tournament is currently not enabled!
        </li>
      </ul>
    </Section>
    <div v-if="matrixError">
      <Section>
        <Headline :size="3">{{matrixError}}</Headline>
      </Section>
    </div>
    <template v-else>
      <Section>
        <section class="select-applicants-table">
          <Headline :size="4" hint=" - you can skip this step if you have created persons in Person Management already">Step 1: Create persons</Headline>
          <div :style="{ marginBottom: '24px' }">
            <Button
                :style="{marginRight: '16px'}"
                @click="redirectToCreate()">
              Create person
            </Button>
            <Button @click="redirectToCreateBulk()">
              Bulk import persons
            </Button>
          </div>
        </section>
      </Section>
      <Section>
        <SelectApplicantsTable />
      </Section>

      <Section>
        <AssignAccreditationTable />
      </Section>
    </template>

    <div class="btn-wrapper">
      <div>
        <Button  type="secondary" @click="changeTab('EventInformation')">
          Back
        </Button>
      </div>

      <Button :disabled="!isValid() ? 'disabled' : null" @click="changeTab('Summary')">
        Continue
      </Button>
    </div>
  </div>
</template>

<script>
import Headline from "@/components/Headline/Headline.component";
import SelectApplicantsTable from "@/components/Table/AccreditationRequest/SelectApplicantsTable.component";
import AssignAccreditationTable from "@/components/Table/AccreditationRequest/AssignAccreditationTable.component";
import Button from "@/components/Button/Button.component";
import Section from "@/components/Section/Section.component";
import get from "lodash.get";
import {DtoApiPersonsRequest} from "@/classes/dto/api/person/dto.api.persons.request";
import RouterNames from "@/router/route.names";

export default {
  name: 'RequestAccreditationApplicantInformation',
  components: {
    Headline,
    SelectApplicantsTable,
    AssignAccreditationTable,
    Button,
    Section
  },
  data: () => ({
    personsDto: new DtoApiPersonsRequest(),
  }),
  computed: {
    matrixError() { return get(this, '$store.state.accreditationRequest.accreditationMatrix.error') },
    eventInfo() { return get(this,'$store.state.accreditationRequest.eventInfo') },
    season() { return get(this, '$store.state.event.selectedSeason', null) },
    isTournamentMode() { return Boolean(this.season?.isTournamentMode); },
    hasAccreditationMatches() { return Boolean(this.season?.hasAccreditationMatches); },
  },
  methods: {
    changeTab(tab) {
      this.$emit('tabchange', tab)
    },
    isValid() {
      const applicants = get(this, '$store.state.accreditationRequest.selectedUsers');
      if (
        !applicants
        || applicants.length === 0
        || (this.isTournamentMode && !this.hasAccreditationMatches)
      ) {
        return false;
      }

      for (let applicant of applicants) {
        if (
          !applicant.category
          || !applicant.subCategory
          || !applicant.accreditationType
          || (this.isTournamentMode && !applicant.venues?.length)
        ) {
          return false;
        }
      }

      return true;
    },
    redirectToCreate() {
      const name = RouterNames.IndexRoutes.DashboardRoutes.PersonRoutes.PersonCreate;
      const params = {};
      params.fromAccreditationCreatePage = true
      this.$router.push({name, params});
    },
    redirectToCreateBulk() {
      const name = RouterNames.IndexRoutes.DashboardRoutes.PersonRoutes.PersonCreateBulk;
      const params = {};
      params.fromAccreditationCreatePage = true
      this.$router.push({name, params});
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../styles/mixins/ellipsis.mixin";

.applicant-information {
  font-size: 12px;

  .btn-wrapper {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    > div .button-component:first-child {
      margin-right: 20px;
    }
  }

  .paragraph-component:last-child {
    padding: 0;
  }

  .accreditation-info-row {
    display: flex;
    min-width: 300px;
    margin-bottom: 10px;
    span:first-child {
      width: 75px;
    }
  }
}
</style>
