<template>
  <div class="event-information">
    <Section>
      <Headline size="3">Event Information</Headline>

      <div v-if="eventType === 'match'" class="event-information-fields">
        <div class="row">
          <div class="col-md-12">

            <InputDropdown
                placeholder="Select matchday"
                :allow-empty="false"
                trackLabel="optionLabel"
                :options="matchDaysList"
                label="Matchday"
                v-model="matchDay"
            />

            <InputDropdown
                placeholder="Select match"
                :disabled="!matchDay"
                :allow-empty="false"
                trackLabel="name"
                :options="matchesList"
                label="Match"
                v-model="match"
            />
          </div>
          <div v-if="this.match" class="col-md-4 pl-0 ml-0 venue-label">Venue</div>
          <div v-if="this.match && this.matchesLoaded" class="col-md-8 venue-value-text">
            {{  this.match.venue ? this.match.venue.localTitle : '' }}
          </div>
        </div>
      </div>
    </Section>

    <div class="btn-wrapper">
      <Button
          :disabled="isDisabled"
          type="primary"
          @click="changeTab('ApplicantInformation')"
      >
        Continue
      </Button>
    </div>
  </div>
</template>

<script>
import get from "lodash.get";

import Button from "@/components/Button/Button.component";
import Headline from "@/components/Headline/Headline.component";
import InputDropdown from '@/components/Inputs/InputDropdown.component';
import Section from '@/components/Section/Section.component';
import moment from "moment";

export default {
  name: "RequestAccreditationEventInfo.view",
  components: {
    Button,
    Headline,
    InputDropdown,
    Section
  },
  data: () => ({
    matchesLoaded: false,
  }),
  watch: {
    matchDay(newVal) {
      if (newVal) {
        this.getMatches(this.matchDay)
        this.match = ''
      }
    },
    match(newVal) {
    },
    season(newVal) {
    },
    globalSelectedSeason(newVal) {
      if(newVal && newVal.isTournamentMode) {
        this.$store.state.accreditationRequest.eventInfo.season = newVal
          this.changeTab('ApplicantInformation')
      }
    },
    matchDaysList(matchDaysList) {
      if(!this.matchDay){
        return;
      }

      //if matchDay is prefilled from another page, make sure it is valid!
      const item = matchDaysList.find(matchday => matchday.matchdayId === this.matchDay.matchdayId);
      if(!item || item.$isDisabled) {
        this.matchDay = '';
        this.match = '';
      }
      else {
        this.getMatches(this.matchDay)
      }
    },
    matchesList(matchesList) {
      if(!this.match){
        this.matchesLoaded = true;
        return;
      }

      //if match is prefilled from another page, make sure it is valid!
      const item = matchesList.find(match => match.matchId === this.match.matchId);
      if(!item || item.$isDisabled) {
        this.match = '';
      }

      this.matchesLoaded = true;
    },
  },
  computed: {
    matchDaysList() {
      return get(this, '$store.state.event.matchDaysList')
    },
    matchesList() {
      return get(this, '$store.state.event.matchesList')
    },
    seasonsList() {
      return get(this, '$store.state.event.seasonsList')
    },

    eventType: {
      get: function () {
        return this.$store.state.accreditationRequest.eventInfo.eventType
      },
      set: function (newVal) {
        this.$store.state.accreditationRequest.eventInfo.eventType = newVal
      }
    },
    matchDay: {
      get: function () {
        return this.$store.state.accreditationRequest.eventInfo.matchDay
      },
      set: function (newVal) {
        this.$store.state.accreditationRequest.eventInfo.matchDay = newVal
      }
    },
    match: {
      get: function () {
        return this.$store.state.accreditationRequest.eventInfo.match
      },
      set: function (newVal) {
        this.$store.state.accreditationRequest.eventInfo.match = newVal
      }
    },
    season: {
      get: function () {
        return this.$store.state.accreditationRequest.eventInfo.season
      },
      set: function (newVal) {
        this.$store.state.accreditationRequest.eventInfo.season = newVal
      }
    },

    isDisabled() {
      return !((this.match && this.matchDay && this.matchesLoaded) || this.season)
    },

    globalSelectedSeason() {
      return get(this, '$store.state.event.selectedSeason')
    }
  },
  methods: {
    getMatches(data) {
      this.$store.dispatch('event/getMatches', {...data, requestType: 'accreditation_request'})
    },
    changeTab(tab) {
      this.$emit('tabchange', tab)
    },
    getMatchKickoffTime(startDateTime) {
      return moment(startDateTime).add(8, 'hours').format('DD.MM.YYYY HH:mm')
    },
  },

  created() {
    this.$store.dispatch('event/getMatchDays', {hasMatchesForAccreditationRequest: 1})

    if(this.globalSelectedSeason && this.globalSelectedSeason.isTournamentMode) {
      this.$store.state.accreditationRequest.eventInfo.season = this.globalSelectedSeason
      this.changeTab('ApplicantInformation')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../styles/mixins/ellipsis.mixin";
@import "../../../../styles/mixins/radio.mixin";

.event-information {
  font-size: 12px;

  @include radio();

  .venue-label {
    font-weight: bold;
  }

  .venue-value-text {
    padding-left: 1.1rem;
    font-size: 12px;
    font-weight: bold;
  }

  .radio-group {
    display: flex;

    > div {
      margin-right: 40px;
    }
  }

  .paragraph-component {
    color: #383838;
  }

  .InputDropdown {
    margin-bottom: 20px;
  }

  .btn-wrapper {
    width: 100%;
    text-align: right;
  }

  .separator {
    height: 1px;
    background: #D8DAE3;
    width: 100%;
    margin: 50px 0;
  }

  ::v-deep .InputLabel__label {
    @include ellipsis;
    min-width: 166px;
    max-width: 166px;
    width: 166px;
    font-size: 12px;
    font-weight: bold;
  }

  ::v-deep .InputLayout__slot {
    width: 100%;

    .multiselect {
      width: 100%;
    }
  }

  ::v-deep .multiselect--disabled {
    background: none;

    .multiselect__select {
      background: none;
    }
  }
}
</style>
